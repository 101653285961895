<template>
  <a-modal :width="500" :class="$style.container" v-model="isShow" :title="(info.id ? '编辑' : '新增')"
  @ok="determine" @cancel="cancel" :maskClosable="false">
    <a-form-model ref="form" :rules="rules" :class="$style.form__ct" class="al-form" :label-col="{ style: 'width: 100px' }" :wrapper-col="{ style: 'width: 300px' }" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="轮播图名称:" prop="name" style="margin-bottom:0">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="图标:" prop="imageUrl" style="margin-bottom:0">
          <a-input v-model="form.imageUrl" placeholder="请输入" style="display:none"/>
          <a-upload name="file" list-type="picture-card" class="avatar-uploader avatar-uploader1"
            :show-upload-list="false" :action="$consts.uploadFileUrl"
            :headers="$store.state.header" :before-upload="(e)=>{beforeUpload(e)}" @change="(e)=>{handleChange(e)}">
            <a-avatar shape="square" v-if="form.imageUrl" :src="form.imageUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
          <div slot="extra">推荐比率265:200，支持jpg、png格式，大小不超过20k</div>
        </a-form-model-item>
        <a-form-model-item label="排序:" prop="sort">
          <a-input-number v-model="form.sort" placeholder="请输入"  style="with:300px"/>
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      overSize: true,
      loading: false,
      isShow: false, // 是否显示弹窗
      info: {}, // 详情回显
      form: {
      },
      rules: {
        name: [
          { required: true, message: '轮播图名称不能为空', trigger: 'blur' }
        ],
        imageUrl: [
          { required: true, message: '图标不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
    isShow (val) {
      if (val) {
        if (this.info.id) {
          this.getCarouselInfo(this.info.id)
        }
      }
    }
  },
  created () {
  },
  methods: {
    async getCarouselInfo (id) {
      const res = await this.$store.dispatch('http', {
        api: 'getCarouselInfo',
        query: {
          id: id
        }
      })
      this.form = res
    },
    beforeUpload (file) {
      this.overSize = false
      let isOverSize
      isOverSize = file.size / 1024 < 20
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$store.dispatch('showToast', { type: 'warning', message: '图片格式不正确' })
      }

      if (!isOverSize) {
        this.$store.dispatch('showToast', { type: 'warning', message: '图片超过了大小,请上传正确大小的图片' })
        this.overSize = true
      }
      return false
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        if (!this.overSize) {
          this.form.imageUrl = info.file.response.result
        }
        this.loading = false
      }
    },
    cancel () {
      this.form = {
      }
    },
    determine (e) {
      delete this.form.gmtCreate
      delete this.form.gmtModified
      let apii = ''
      if (this.form.id) {
        apii = 'updateCarousel'
      } else {
        apii = 'addCarousel'
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let api = apii
          this.$store.dispatch('http', {
            api,
            params: this.form,
            success: () => {
              this.cancel()
              this.$store.dispatch('showToast', { message: (this.info.id ? '编辑' : '新增') + '成功~' })
              this.isShow = false
              this.$emit('save')
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 120px;
  height: 120px;
}
.ant-upload-select-picture-card{
  margin:0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatar-uploader1 .ant-avatar{
  width:100px;
  height: 100px;
  margin: 0;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
