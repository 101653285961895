<template>
  <div>
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button class="add-bt" type="primary" @click="$refs.dialog.info = {};$refs.dialog.isShow = true">新增</a-button>
    </Breadcrumb>
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="pagination">

      <span slot="action1" slot-scope="scope">
        <a  @click="zoom(scope)">
          <a-avatar shape="square"
                style="width:84px;height:84px;float: left;"
                :src="scope" />
        </a>
      </span>
      <span slot="action2" slot-scope="scope">
        <div>创建时间：{{ scope.gmtCreate }}</div>
        <div v-if="scope.gmtModified!=null">更新时间：{{ scope.gmtModified }}</div>
      </span>
      <span slot="action" slot-scope="text,record">
        <a style="margin-right:20px;" @click="sortCarousel(record.sort,record.id)">排序</a>
        <a style="margin-right:20px;" @click='deleteCarousel(record.id)'>删除</a>
      </span>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="carouselImag"/>
    </a-modal>
    <AddCarousel ref="dialog" @save="reloadList"></AddCarousel>
    <Sort @sort="reloadList" ref="CarouselSort"></Sort>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import AddCarousel from './dialog/addCarousel.vue'
import Sort from './dialog/sort.vue'
export default {
  components: {
    Breadcrumb,
    AddCarousel,
    Sort
  },
  data () {
    return {
      breadList: [
        {
          name: 'CMS轮播图管理',
          url: ''
        }
      ],
      visible: false,
      carouselImag: '',
      isQueryLoading: false,
      form: {
        id: ''
      },
      formRefuse: {
        id: '',
        failReason: ''
      },
      columns: [
        { title: '轮播图名称', dataIndex: 'name', key: 'name' },
        { title: '轮播图', dataIndex: 'imageUrl', key: 'imageUrl', scopedSlots: { customRender: 'action1' } },
        { title: '排序', dataIndex: 'sort', key: 'sort' },
        { title: '时间', key: '', scopedSlots: { customRender: 'action2' } },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: [],
      selectedRowKeys: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    sortCarousel (sortNum, id) {
      this.$refs.CarouselSort.init(sortNum, id, 'cms')
    },
    deleteCarousel (id) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除轮播图吗？',
        yes: async _ => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'delCarousel',
            query: {
              id: id
            },
            success: () => {
              this.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.queryList()
            }
          })
        }
      })
    },
    resetForm (formName) {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        // this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'carouselPage',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.carouselImag = scope
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
